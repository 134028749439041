import { motion } from "framer-motion";
import { useEffect } from "react";
import {
  Carousel,
  CarouselItem,
  Container,
  Row,
  Image,
  Col,
} from "react-bootstrap";

interface PhotoSet {
  title: string;
  images: string[];
}

export const PhotoGallery = () => {
  useEffect(() => {
    document.title = "Mohamad Tarabin - Photo Gallery";
  }, []);

  const photosList: PhotoSet[] = require("./photos.json").photos;

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        viewport={{ once: true }}
      >
        <Container
          style={
            isMobile
              ? {
                  marginBottom: "100px",
                  marginTop: "50px",
                  width: "99vw",
                }
              : {
                  marginBottom: "100px",
                  marginTop: "50px",
                  width: "50vw",
                }
            // { marginBottom: "100px", width: "50vw" }
          }
        >
          <h1
            style={{
              fontSize: "40px",
              marginBottom: "40px",
              textAlign: "center",
            }}
          >
            Photo Gallery
          </h1>

          {photosList.map((photoSet) => (
            <Row style={{ marginBottom: "100px" }}>
              <h3>{photoSet.title}</h3>
              <Carousel>
                {photoSet.images.map((image) => (
                  <CarouselItem
                    style={
                      isMobile
                        ? {
                            width: "100%",
                            height: "60vh",
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                          }
                        : {
                            width: "100%",
                            height: "70vh",
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            // position: "absolute",
                          }
                    }
                  >
                    {isMobile ? (
                      <Image src={image} fluid />
                    ) : (
                      <Image
                        className="mx-auto d-block"
                        src={image}
                        style={{
                          height: "100%",
                        }}
                      />
                    )}
                  </CarouselItem>
                ))}
              </Carousel>
            </Row>
          ))}
        </Container>
      </motion.div>
    </>
  );
};
