import { ReactNode, useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

interface Props {
  children: ReactNode;
  closedStateText: string; // closedStateText?: string; -> Question mark means it is optional
  openedStateText: string;
}

export const ExpandableComponent: React.FC<Props> = ({
  children,
  closedStateText,
  openedStateText,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* <div style={{ display: "block", width: 700, padding: 30 }}> */}
      {/*<div style={{ width: 660, height: "auto" }}> */}
      <Button
        variant="secondary"
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        aria-controls="collapseID"
      >
        {open ? openedStateText : closedStateText}
      </Button>
      <Collapse in={open}>
        <div
          // id="collapseID"
          style={{
            textAlign: "justify",
            marginTop: "10px",
          }}
        >
          {children}
        </div>
      </Collapse>
      {/* </div>*/}
      {/* </div> */}
    </>
  );
};
