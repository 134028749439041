import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import { motion } from "framer-motion";
import { useEffect } from "react";

interface Award {
  title: string;
  date: string;
  description: string;
  images: string[];
}

export const Awards = () => {
  useEffect(() => {
    document.title = "Mohamad Tarabin - Awards";
  }, []);

  const awardsJSON = require("./awards.json");
  const awardsList: Award[] = awardsJSON.awards;

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
        // viewport={{ once: true }}
      >
        <div
          className="text-center"
          style={
            isMobile
              ? {
                  marginTop: "100px",
                  marginBottom: "200px",
                }
              : {
                  marginTop: "150px",
                  marginBottom: "200px",
                }
          }
        >
          <h1 style={{ fontSize: "70px", marginBottom: "20px" }}>
            Honors and Awards
          </h1>
          <Image
            src="images/icons/achievement-award-medal-icon.svg"
            style={{ width: "100px" }}
          />
        </div>
      </motion.div>

      <Container
        style={{
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // backgroundColor: "red",
        }}
      >
        {awardsList.map((award, index) => (
          <motion.div
            initial={{
              x: index % 2 === 0 ? "50vw" : "-50vw",
            }}
            // x has to be somewhat visible in the viewport. It cannot be completely outside otherwise whileInView does not trigger.
            whileInView={{ x: 0 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
            style={{
              backgroundColor:
                index % 2 === 0 ? "rgba(255, 255, 255, 0.5)" : "black",
              // height: "300px",
              marginBottom: "150px",
            }}
          >
            <Row
              // The below makes it so that the column distribution changes based on screen size
              // I have not used order but I could have added it to the second column and controlled the order
              // xs={{ span: 12, order: 0 }}
              // lg={{ span: 12, order: 0 }}
              style={{
                // backgroundColor: "purple",
                display: "flex",
                flexDirection: "column",
                // JustifyContent centers along the main axis (the vertical one in this case)
                justifyContent: "center",
                // Align items centers along the cross axis (horizontal in this case)
                alignItems: "center",
                textAlign: "center",
                padding: "50px",
              }}
            >
              <h1>{award.title}</h1>
              <h2>{award.date}</h2>
              <h5>{award.description}</h5>
            </Row>

            {/* Add image */}
            {award.images != null ? (
              <Row>
                {award.images.length > 1 ? (
                  <Carousel>
                    {award.images.map((imageUrl) => (
                      <Carousel.Item>
                        <Image
                          src={imageUrl}
                          fluid
                          // style={{ flex: 1, width: undefined, height: undefined }}
                        />
                        {/* <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>
                                  Nulla vitae elit libero, a pharetra augue mollis
                                  interdum.
                                </p>
                              </Carousel.Caption> */}
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  <Image
                    src={award.images[0]}
                    fluid
                    style={{ maxHeight: "400px" }}
                  />
                )}
              </Row>
            ) : null}
          </motion.div>
        ))}
      </Container>
    </>
  );
};
