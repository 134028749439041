import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

export const Footer = () => {
  return (
    <footer className="bg-dark text-light py-4">
      <Container>
        <Row>
          <Col lg={{ span: 2 }}>
            <h4>Contact</h4>
            <p>Email: tarabinm@mcmaster.ca</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <a href="https://www.linkedin.com/in/mohamad-tarabin-aa19471b8">
                <Image
                  src="images/icons/linkedin.png"
                  width="40px"
                  height="40px"
                />
              </a>
              <a href="https://www.researchgate.net/profile/Mohamad-Tarabin">
                <Image
                  src="images/icons/Research Gate Logo.webp"
                  width="40px"
                  height="40px"
                />
              </a>
              <a href="https://scholar.google.com/citations?user=-xaanw0AAAAJ&hl=en">
                <Image
                  src="images/icons/Google Scholar Rectangle.png"
                  width="40px"
                  height="40px"
                />
              </a>
            </div>
            {/* <p>Phone: 123-456-7890</p> */}
          </Col>
          {/* <Col>
            <h4>Links</h4>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Services</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </Col> */}
          <Col>
            {/* I don't want instagram for now */}
            {/* <a href="https://www.instagram.com/mohamad_ht99" className="mx-4">
              <Image
                src="images/icons/instagram.webp"
                width="40px"
                height="40px"
              />
            </a> */}
          </Col>
        </Row>
        <hr className="bg-light" />
        <p className="text-center">
          &copy; 2024 Mohamad Tarabin. All rights reserved.
        </p>
      </Container>
    </footer>
  );
};
