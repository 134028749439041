import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import React from "react";
// import '../css/NavBarCSS.css'

export const NavBar = () => {
  const [activeKey, setActiveKey] = useState("/");

  // const handleSelect = (selectedKey: any) => {
  //   setActiveKey(selectedKey);
  // };

  const location = useLocation();
  React.useEffect(() => {
    // runs on location, i.e. route, change
    // console.log("handle route change here", location);
    setActiveKey(location.pathname);
  }, [location]);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
      /* you can also use 'auto' behaviour 
           in place of 'smooth' */
    });
  };

  return (
    <Navbar expand="lg" variant="dark">
      {/* <Navbar collapseOnSelect bg="dark" variant='dark' expand="lg"></Navbar> */}
      <Container>
        {/* <Navbar.Brand href="/">Home</Navbar.Brand> */}
        {/* The below adds the toggle on the right to show the drop down when screen is small */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            activeKey={activeKey}
            onSelect={(key, event) => setActiveKey(key as string)}
            // className="m-auto"
            className="justify-content-center" // Use this along with flex: 1 to center in the middle
            variant="underline"
            style={{
              flex: 1,
              // display: "flex",
              // flexDirection: "row",
              // justifyContent: "center",
              alignItems: "baseline" /* Vertically center items */,
              // margin: "auto" /* Horizontal alignment */,
              // height: "100%",
              // width: "100%",
              // justifyContent: "space-between", // This maximizes space between the children
              // backgroundColor: "orange",
            }}
          >
            {/* Using href causes full page reload. Using 'as' with 'to' does not refresh the page */}
            <Nav.Link as={Link} to="/" eventKey="/" style={{ fontSize: 30 }}>
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/publications" eventKey="/publications">
              Publications
            </Nav.Link>
            <Nav.Link as={Link} to="/awards" eventKey="/awards">
              Awards
            </Nav.Link>
            <Nav.Link as={Link} to="/developments" eventKey="/developments">
              Developments
            </Nav.Link>
            <Nav.Link as={Link} to="/photogallery" eventKey="/photogallery">
              Photo Gallery
            </Nav.Link>
            <Button variant="outline-light" onClick={scrollToBottom}>
              Contact me
            </Button>

            {/* <Nav.Link as={Link} to="/contact" eventKey="/contact">
              Contact Me
            </Nav.Link> */}
            {/* <Nav.Link href="awards" eventKey="awards">
              Awards
            </Nav.Link> */}
            {/* <Nav.Link href="developments">Developments</Nav.Link> */}
          </Nav>

          {/* <Nav
            className="ms-auto"
            variant="underline"
            style={{
              alignItems: "baseline",
              display: "flex",
              flexDirection: "row", // I would like these to stay in a row direction even when collapsed
            }}
          > */}
          {/* Instagram is not a good idea */}
          {/* <Nav.Link
              href="https://www.instagram.com/mohamad_ht99"
              // className="ms-auto"
            >
              <Image
                src="images/icons/instagram.webp"
                width="40px"
                height="40px"
              />
            </Nav.Link> */}

          {/* I want nothing in the NavBar for now. This turns on/off linkedin */}
          {/* <Nav.Link href="https://www.linkedin.com/in/mohamad-tarabin-aa19471b8">
              <Image
                src="images/icons/linkedin.png"
                width="40px"
                height="40px"
              />
            </Nav.Link> */}
          {/* </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
