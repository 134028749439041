import Container from "react-bootstrap/Container";
// import "../css/AnimationsCSS.css";
import { motion } from "framer-motion";
import image1 from "./My Images/Image1.jpg";
import { Button, Card, CardHeader, Col, Image, Row } from "react-bootstrap";
import { ExpandableComponent } from "../../components/ExpandableComponent";
import { useEffect } from "react";

// import Particles from "../components/HomeParticles";

// import { useEffect, useState } from "react";
// import { initParticlesEngine } from "@tsparticles/react";
// // import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// // import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
// import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// // import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.
// import { HomeParticles } from "../../particles/HomeParticles";

export const Home = () => {
  useEffect(() => {
    document.title = "Mohamad Tarabin - Home";
  }, []);
  // const [init, setInit] = useState(false);
  // useEffect(() => {
  //   initParticlesEngine(async (engine) => {
  //     // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
  //     // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //     // starting from v2 you can add only the features you need reducing the bundle size
  //     //await loadAll(engine);
  //     //await loadFull(engine);
  //     await loadSlim(engine);
  //     //await loadBasic(engine);
  //   }).then(() => {
  //     setInit(true);
  //   });
  // }, []);

  // if (!init) return <></>;

  const isMobile = window.innerWidth < 768;

  return (
    <>
      {/* <motion.div
        // initial={{ opacity: 0, scale: 2 }}
        // animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 2 }}
        // whileHover={{ scale: 1.2 }}
      >
        <h1 style={{ color: "var(--primary-color)", textAlign: "center" }}>
          Hello there... :)!
        </h1>
      </motion.div> */}

      <Container
        fluid
        style={{
          // height: "1500px",
          marginTop: "50px",
          // backgroundColor: "white",
        }}
      >
        <Row>
          <Col
            xs={{ span: 12 }}
            lg={{ span: 6, order: 0, offset: 3 }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
            >
              <h1>
                Hi, my name is{" "}
                <span className="color-gradient bold">Mohamad Tarabin</span>
                {/* <br />
                I'm a Civil Engineer and a Ph.D. Student. */}
                {/* <br />
                I'm a Developer. */}
              </h1>
              <h1 className="mt-5">
                I'm a Civil Engineer and a Scientific Researcher.
              </h1>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }} //x: "20vw"
              animate={{ opacity: 1 }} //x: 0
              transition={{ delay: 1, duration: 2 }}
            >
              <h1 className="mt-5">Welcome to my Portfolio!</h1>
              <motion.div
                whileHover={{ scale: 1.2 }}
                transition={{ duration: 1 }}
              >
                <Image
                  src={image1}
                  fluid
                  style={{ marginTop: "50px", maxWidth: "600px" }}
                />
              </motion.div>
            </motion.div>
          </Col>
        </Row>

        {/* About me */}
        {/* About me */}
        {/* About me */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5, duration: 4 }}
        >
          <Row
            style={{
              marginTop: "100px",
              marginBottom: "100px",
              justifyContent: "center",
            }}
          >
            <Col xs={{ span: 12, order: 0 }} lg={{ span: 7, order: 0 }}>
              <Card
                style={
                  {
                    // width: "50vw"
                  }
                }
              >
                <Card.Body>
                  <Card.Title className="bold">About Me</Card.Title>
                  <div className="separator"></div>
                  <Card.Text style={{ textAlign: "justify" }}>
                    I'm a dedicated civil engineer with a passion for research
                    and education. I have a strong educational background,
                    including a Bachelor's degree in civil engineering from Abu
                    Dhabi University and a Master’s degree from the University
                    of Sharjah. Currently, I am pursuing a Ph.D. at McMaster
                    University, focusing on damage-resistant radiation shielding
                    ultra-high-performance concrete for nuclear containment
                    structures.
                    <br />
                    <br />
                    <ExpandableComponent
                      closedStateText="Expand"
                      openedStateText="Hide"
                    >
                      <div className="bold">Motives</div>I have always been
                      really passionate about education and learning new things.
                      I love the concept of creating things from scratch and
                      coming up with new ideas. I first started applying this
                      passion when I was 13 years old through learning
                      programming, because it allowed me to create anything I
                      imagined, and I ended up developing my skills as a
                      programmer to the level where I can generate income
                      through selling software’s to the public all through
                      self-teaching. Although I adored programming, I decided to
                      major in a different specialization and I chose to study
                      civil engineering instead so that I can have multiple
                      talents and be able to to combine different skills and
                      come up with greater results.
                      {/* various options for
                  work, and I thought this would allow me to combine different
                  skills and come up with greater results. */}
                      <br />
                      <br />
                      Being a competitive student has always motivated me to
                      perform my responsibilities to the best of my capabilities
                      and to achieve great results. I finished high school as
                      the top student in my class, and I then finished my
                      bachelor’s in civil engineering at Abu Dhabi University
                      being the top student in the civil engineering department
                      with a CGPA of 3.99. Furthermore, I finished my Master's
                      degree at the University of Sharjah with a perfect CGPA of
                      4.0 and I’m now on track to finish my PhD degree in civil
                      engineering at McMaster University with a CGPA of 4.0. I
                      believe my excellence in studies is due to my enthusiasm
                      about learning and the fact that I take my education more
                      seriously than others and I put all of my effort into it.
                      During my studies, I was always working hard towards
                      understanding the different concepts in depth, and this
                      led to a new passion for me. My colleagues were struggling
                      in the major courses and relied on me for further
                      explanations and clarifications, and although it was not
                      what I anticipated, but it sparked a new affection for me
                      as I discovered that I enjoy teaching. The joy of seeing
                      someone passing a course and considering me their success
                      reason for all the help that I provided really made me
                      happy and encouraged me to work harder and reach higher
                      levels. This opened my eyes towards a new career
                      objective; however, for me to achieve such a career, I
                      would require a lot of high-quality tutoring and training
                      in the civil engineering field, which I am hoping to
                      achieve throughout my graduate studies.
                      <br />
                      <br />
                      <div className="bold">Research</div>
                      Studying civil engineering these past years has really
                      increased my fondness for this discipline and it made me
                      eager to learn more and discover new things on my own.
                      During my bachelor’s, I did a lot of researching on many
                      concepts on my own such as seismic building designs, shear
                      walls, slender columns, two-way slabs design, and other
                      structural elements that were not included in my
                      bachelor’s degree. I found it important that I further my
                      education and look towards other topics that have not been
                      fully understood yet.
                      <br />
                      <br />
                      During my master’s degree, I worked as a graduate research
                      assistant at the Research Institute of Sciences and
                      Engineering (RISE), Department of Sustainable Construction
                      Materials and Structural Systems. As a result, I had the
                      opportunity to work with many professors on different
                      topics and experience the world of professional research
                      for the first time. My role was to conduct literature
                      reviews, create numerical simulations using the finite
                      element method in ABAQUS, develop analysis software, and
                      execute lab experiments.
                      <br />
                      <br />
                      I'm presently working as a research and teaching assistant
                      at McMaster University. My responsibilities primarily
                      revolve around research focused on the development of
                      damage-resistant radiation-shielding
                      ultra-high-performance concrete. Additionally, I fulfill
                      teaching assistant duties, which involve conducting weekly
                      tutorials, creating quizzes, and evaluating assignments,
                      projects, and examinations. My research aims to develop a
                      novel type of ultra-high-performance concrete with optimum
                      cement content, high radiation shielding, and an extended
                      energy absorption capacity to resist high internal
                      pressure. This entails conducting comprehensive literature
                      reviews, implementing experimental programs to optimize
                      mix designs, and constructing finite element models to
                      simulate nuclear containment structures. The results of
                      this research are expected to enhance the safety of
                      nuclear infrastructure in Canada and worldwide, ensuring a
                      secure setting for the generation of sustainable green
                      energy.
                    </ExpandableComponent>
                    {/* <br />
                With a track record of published
                research and a commitment to continuous learning, I aim to
                contribute to sustainable and innovative construction practices.
                <br />
                <Row>
                  <Col>
                    Skill Set: Proficient in engineering software (AutoCAD,
                    ETABS, Synchro, etc.), programming languages (Java, C#,
                    Python, HTML, JavaScript, MATLAB), Microsoft Office Suite,
                    technical writing, and presentations. My expertise spans
                    engineering software, programming, and technical writing.
                  </Col>
                  <Col>
                    Research and Teaching Experience: Currently a Research and
                    Teaching Assistant at McMaster University, with prior
                    research assistantship at the University of Sharjah and a
                    brief internship in construction.
                  </Col>
                </Row>
                <br />
                <br />
                Academic Achievements: Consistent academic excellence with a
                CGPA of 4/4 in graduate studies and 3.99/4 in undergraduate,
                alongside multiple scholarships and awards.
                <br />
                Research Contributions: Published and pending review papers in
                reputable journals, focusing on concrete technology, crack
                propagation, and the application of machine learning to
                engineering.
                <br /> */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </motion.div>

        {/* Skills */}
        {/* Skills */}
        {/* Skills */}
        {/* <motion.div
          initial={{ x: "-20vw" }}
          whileInView={{ x: 0 }}
          transition={{ duration: 2 }}
        > */}
        <Row
          style={{
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <Col
            xs={{ span: 12, order: 0 }}
            lg={{ span: 4, order: 0, offset: 2 }}
          >
            {/* <h1 style={{ textAlign: "center" }}>Skills</h1> */}
            <div
              style={{
                borderTop: "1px solid white" /* Define the border style */,
                margin: "10px 0" /* Adjust margin as needed */,
              }}
            />
            <h1>Skills</h1>
            <Row>
              <Col>
                <ul>
                  <li>
                    Engineering software’s: AutoCAD, ETABS, Synchro, STAAD
                    Foundation, Slides, Primavera, SAFE, ABAQUS
                  </li>
                  <li>
                    Programming (Java / C# / Python, HTML, JavaScript, MATLAB)
                  </li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li>
                    Microsoft software’s: Word, PowerPoint, Excel, Project
                  </li>
                  <li>Technical writing and oral presentations</li>
                  <li>Time management and critical thinking</li>
                </ul>
              </Col>
            </Row>
            {/* <div
              style={{
                borderTop: "1px solid white",
                margin: "10px 0",
              }}
            /> */}
          </Col>
        </Row>
        {/* </motion.div> */}

        {/* Education */}
        {/* Education */}
        {/* Education */}
        {/* <motion.div
          initial={{ x: "20vw" }}
          whileInView={{ x: 0 }}
          transition={{ duration: 2 }}
        > */}
        <Row
          style={{
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <Col
            xs={{ span: 12, order: 0 }}
            lg={{ span: 4, order: 0, offset: 6 }}
          >
            <div
              style={{
                borderTop: "1px solid white" /* Define the border style */,
                margin: "10px 0" /* Adjust margin as needed */,
              }}
            />
            <h1>Education</h1>
            <div className="education-date"> September 2023 - Present</div>
            <ul>
              <li>
                Ph.D. Candidate in Civil Engineering / McMaster University
              </li>
              - Thesis title: Development of Damage Resistant Radiation
              Shielding Ultra-High-Performance Concrete for Nuclear Reactors and
              Small Modular Reactors Containment Structures.
              <br />
              - Coursework: Advanced Concrete Technology for NetZero and
              Sustainable Construction, Multiscale Approach for the Design of
              Sustainable Cementitious Composites
              <br />
              - Audited courses: Applications of Machine Learning to Materials
              and Chemical Engineering
              <br />- CGPA: 4/4
            </ul>
            <div className="education-date"> September 2021 – July 2023</div>
            <ul>
              <li>M.Sc. in Civil Engineering / University of Sharjah</li>
              - Thesis title: Investigation of The Bond Behaviour Between
              Polyethylene ECC and Deformed Steel Rebar Under Various Load
              Conditions.
              <br />
              - Coursework: Finite element methods, Environmental Impact
              Assessment, Advanced Numerical Methods, Advanced Structural
              Mechanics, Advanced Materials in Construction, Applied Engineering
              Statistics, Graduate Seminars, Computer Vision and Image
              Processing, Management of Construction projects
              <br />- CGPA: 4/4
            </ul>
            <div className="education-date"> September 2017 - July 2021</div>
            <ul>
              <li>B.Sc. in Civil Engineering / Abu Dhabi University</li>
              - Graduation project title: Structural design of a 7-story
              residential building.
              <br />- Coursework: AutoCAD, Statics, Mechanics of materials,
              Structural analysis, Structural systems, reinforced concrete
              design I, Reinforced concrete design 2, Structural steel design,
              Surveying, Transportation engineering, Highway engineering,
              Construction materials, Geology, Geotechnical engineering,
              Foundation engineering, Fluid mechanics, Hydraulics, Hydrology and
              urban water systems, Environmental engineering, Construction
              management, and Engineering Economy.
              <br />- CGPA: 3.99/4
            </ul>
            {/* <Col>
                <ul>
                  <li>
                    Microsoft software’s: Word, PowerPoint, Excel, Project
                  </li>
                  <li>Technical writing and oral presentations</li>
                </ul>
              </Col> */}
            {/* <div
              style={{
                borderTop: "1px solid white",
                margin: "10px 0",
              }}
            /> */}
          </Col>
        </Row>
        {/* </motion.div> */}

        {/* Work experience */}
        {/* Work experience */}
        {/* Work experience */}
        {/* <motion.div
          initial={{ x: "-20vw" }}
          whileInView={{ x: 0 }}
          transition={{ duration: 2 }}
        > */}
        <Row
          style={{
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <Col
            xs={{ span: 12, order: 0 }}
            lg={{ span: 4, order: 0, offset: 2 }}
          >
            <div
              style={{
                borderTop: "1px solid white" /* Define the border style */,
                margin: "10px 0" /* Adjust margin as needed */,
              }}
            />
            <h1>Work Experience</h1>
            <div className="education-date">September 2023 – Present</div>
            <ul>
              <li>Research And Teaching Assistant / McMaster University</li>-
              Currently employed as a research and teaching assistant at
              McMaster University, my responsibilities primarily revolve around
              research focused on the development of radiation-shielding
              ultra-high-performance concrete. This entails conducting
              comprehensive literature reviews, implementing experimental
              programs to optimize mix designs, and constructing finite element
              models to simulate containment structures. Additionally, I fulfill
              teaching assistant duties, which involve conducting weekly
              tutorials, creating quizzes, and evaluating assignments, projects,
              and examinations.
            </ul>
            <div className="education-date">
              September 2021 – September 2023
            </div>
            <ul>
              <li>Research Assistant / University of Sharjah</li>- Worked as a
              research assistant at the Research Institute of Sciences and
              Engineering (RISE), Department of Sustainable Construction
              Materials and Structural Systems. My role was to conduct
              literature reviews, create numerical simulations using the finite
              element method in ABAQUS, and execute lab experiments.
            </ul>
            <div className="education-date">January 2021 – February 2021</div>
            <ul>
              <li>Internship / Al Raghib Gen. Cons.</li>- Worked as a site
              engineer in a contracting company for 2 months and learned about
              the initial construction activities that take place in building a
              residential villa.
            </ul>
            <div className="education-date">2015 - Present</div>
            <ul>
              <li>Freelance Programmer</li>
            </ul>
            {/* <Col>
                <ul>
                  <li>
                    Microsoft software’s: Word, PowerPoint, Excel, Project
                  </li>
                  <li>Technical writing and oral presentations</li>
                </ul>
              </Col> */}
            {/* <div
              style={{
                borderTop: "1px solid white",
                margin: "10px 0",
              }}
            /> */}
          </Col>
        </Row>
        {/* </motion.div> */}

        <Container
          style={{
            marginBottom: "100px",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={isMobile ? { once: true } : { once: false }}
          >
            <h1
              className="text-center"
              style={{
                marginBottom: "40px",
              }}
            >
              You have reached the end of the page. Check out my other pages!
            </h1>
          </motion.div>

          <Row>
            <Col
              xs={{ span: 12, order: 0 }}
              lg={{ span: 3, order: 0 }}
              style={{ marginBottom: "30px" }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={
                  isMobile
                    ? { duration: 0.5, delay: 0.5 }
                    : { duration: 0.5, delay: 0.5 }
                }
                viewport={isMobile ? { once: true } : { once: false }}
              >
                <Card className="text-center">
                  <Card.Header>Publications</Card.Header>
                  <Card.Img
                    // variant="top"
                    src="https://ars.els-cdn.com/content/image/1-s2.0-S2352710224005370-gr7_lrg.jpg"
                    style={{ height: "250px" }}
                  />
                  <Card.Body>
                    {/* <Card.Text>Check out my publications here</Card.Text> */}
                    <Button variant="secondary" href="publications">
                      View publications
                    </Button>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>

            <Col
              xs={{ span: 12, order: 0 }}
              lg={{ span: 3, order: 0 }}
              style={{ marginBottom: "30px" }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={
                  isMobile
                    ? { duration: 0.5, delay: 0.5 }
                    : { duration: 0.5, delay: 0.75 + 0.5 }
                }
                viewport={isMobile ? { once: true } : { once: false }}
              >
                <Card className="text-center">
                  <Card.Header>Awards</Card.Header>
                  <Card.Img
                    // variant="top"
                    src="images/Awards/Abu Dhabi University/Honors/2017-2018 Fall.jpg"
                    style={{ height: "250px" }}
                  />
                  <Card.Body>
                    {/* <Card.Text>Check out my awards here</Card.Text> */}
                    <Button variant="secondary" href="awards">
                      View awards
                    </Button>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>

            <Col
              xs={{ span: 12, order: 0 }}
              lg={{ span: 3, order: 0 }}
              style={{ marginBottom: "30px" }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={
                  isMobile
                    ? { duration: 0.5, delay: 0.5 }
                    : { duration: 0.5, delay: 1 + 0.75 }
                }
                viewport={isMobile ? { once: true } : { once: false }}
              >
                <Card className="text-center">
                  <Card.Header>Developments</Card.Header>
                  <Card.Img
                    // variant="top"
                    src="images/Developments/RS-UHPC Designer.png"
                    style={{ height: "250px" }}
                  />
                  <Card.Body>
                    {/* <Card.Text>Check out my developments</Card.Text> */}
                    <Button variant="secondary" href="developments">
                      View developments
                    </Button>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>

            <Col
              xs={{ span: 12, order: 0 }}
              lg={{ span: 3, order: 0 }}
              style={{ marginBottom: "30px" }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={
                  isMobile
                    ? { duration: 0.5, delay: 0.5 }
                    : { duration: 0.5, delay: 1.25 + 1 }
                }
                viewport={isMobile ? { once: true } : { once: false }}
              >
                <Card className="text-center">
                  <Card.Header>Photo Gallery</Card.Header>
                  <Card.Img
                    // variant="top"
                    src="images/PhotoGallery/ASET Conference/Pic1.JPG"
                    style={{ height: "250px" }}
                  />
                  <Card.Body>
                    {/* <Card.Text>Check out my developments</Card.Text> */}
                    <Button variant="secondary" href="photogallery">
                      View photo gallery
                    </Button>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>
        </Container>

        {/* <div
            style={{
              // width: "100%",
              // backgroundImage: `url(${image1})`,
              // backgroundSize: "cover",

              // display: "flex",
              // flexDirection: "column",
              // objectFit: "fill",
              // backgroundColor: "red",
            }}
          >
          </div> */}
      </Container>

      {/* {init ? (
        <>
          <HomeParticles />
        </>
      ) : null} */}
    </>
  );
};
