import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { ReadMore } from "../../components/ReadMore";
// import "../../css/PublicationsCSS.css";
// import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useEffect } from "react";

// const publicationsFile: string = "publications.json";

// If we import the JSON file, then it is loaded as soon this file is imported (On server enable)
// import publicationsJSON from "./publications.json";

interface Publication {
  title: string;
  date: string;
  type: string;
  abstract: string;
  link: string;
  image: string;
  "sjr-id": string | null;
}

//Use state calls this function
export const Publications = () => {
  useEffect(() => {
    document.title = "Mohamad Tarabin - Publications";
  }, []);

  const publicationsJSON = require("./publications.json");
  const publicationList: Publication[] = publicationsJSON.publications;

  // The below is not updated on window resize but I don't care.
  const isMobile = window.innerWidth < 768;

  // The below is if we want to use FETCH
  //=======================================
  // //The below has to be used. We cannot use a regular list because then the UI is not updated if the list is
  // const [publicationList, updatePublicationList] = useState<Publication[]>([]);

  // //Load publications
  // //We have to use useEffect with empty dependency at the end []. This is because this fetch call
  // //Updates a state, therefore re-renders (recalls this function), which then fetches again creating an infinite
  // //Loop. The useEffect function with empty dependency makes it so that it is only called once (initial render)
  // useEffect(() => {
  //   console.log("Fetch publications file");
  //   fetch(publicationsFile)
  //     .then((response) => {
  //       //This is async
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((jsonFile) => {
  //       const list: Publication[] = jsonFile.publications;

  //       updatePublicationList(list);
  //     });
  // }, []);
  //=======================================

  return (
    <>
      {/* Container is not exactly necessary, but it is better for organized spacing */}
      <Container>
        <h3
          style={{
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          Publications{" "}
          <span style={{ color: "gray" }}>({publicationList.length})</span>
        </h3>

        <div className="publications-container">
          {publicationList.map((publication) => (
            // <Row>
            //mx-auto means margin x auto. This is a feature in bootstrap
            // className="my-5" -> Means margin y is set to 5
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5 }}
              // viewport={{ once: true }}
            >
              <Card>
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Header>{publication.type}</Card.Header>
                <Card.Body>
                  <Container fluid>
                    <Row>
                      {/* <Col class="col-7"> */}
                      <Col
                        xs={{ span: 12, order: 0 }}
                        lg={{ span: 7, order: 0 }}
                      >
                        <Card.Title className="justify-text bold">
                          {publication.title}
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          {publication.date}
                        </Card.Subtitle>
                        <Card.Text className="justify-text">
                          <ReadMore charlimit={isMobile ? 500 : 1000}>
                            {"Abstract: " + publication.abstract}
                          </ReadMore>
                        </Card.Text>
                      </Col>

                      <Col
                      // class="col-5"
                      >
                        {/* You make the row 'relative' so that whatever uses absolute position is relative to this row */}
                        <Row style={{ position: "relative" }}>
                          {publication.image != undefined ? (
                            <Image
                              src={publication.image}
                              fluid
                              className="my-2"
                            />
                          ) : null}
                          {publication["sjr-id"] != undefined ? (
                            <a
                              href={
                                "https://www.scimagojr.com/journalsearch.php?q=" +
                                publication["sjr-id"] +
                                "&tip=sid&exact=no"
                              }
                            >
                              <Image
                                src={
                                  "https://www.scimagojr.com/journal_img.php?id=" +
                                  publication["sjr-id"]
                                }
                                style={{
                                  width: "25%",
                                  // height: "40%",
                                  position: "absolute",
                                  top: "2%",
                                  right: "2%",
                                }}
                              />
                            </a>
                          ) : null}
                        </Row>

                        <Row>
                          <Col className="text-center">
                            <Button
                              variant="primary"
                              href={publication.link}
                              className="my-2"
                            >
                              View publication
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </motion.div>
            // </Row>
          ))}
        </div>
      </Container>
    </>
  );
};
