import { Button, Card, Col, Container, Row } from "react-bootstrap";
// import { ReadMore } from "../../components/ReadMore";
import Image from "react-bootstrap/Image";
import { motion } from "framer-motion";
import { ExpandableComponent } from "../../components/ExpandableComponent";
import { useEffect } from "react";

interface Changelog {
  oldVersion: string;
  newVersion: string;
  description: string;
  changes: string[];
}

export const Developments = () => {
  useEffect(() => {
    document.title = "Mohamad Tarabin - Developments";
  }, []);

  const changelogJSON = require("./changelog.json");
  const radiationSoftwareChangelogs: Changelog[] =
    changelogJSON.radiationSoftware;

  return (
    <>
      <Container>
        <h3
          style={{
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          Developments
        </h3>

        <div className="publications-container">
          {/* {developmentsList.map((development) => ( */}
          {/* // <Row>
            //mx-auto means margin x auto. This is a feature in bootstrap
            // className="my-5" -> Means margin y is set to 5 */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5 }}
            // viewport={{ once: true }}
          >
            {/* "title": "McMaster Radiation Shielding UHPC Designer"
        ,"description": "This is a description of a software"
        ,"downloadLink": "https://1drv.ms/u/c/db9c58436583d63b/EV3QryuWNvxFvsVju9M5COYBFF2cJKSN0tDT4460Gs8x3w"
        ,"image": "images/Developments/RS-UHPC Designer.png" */}
            <Card>
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              {/* <Card.Header>{development.title}</Card.Header> */}
              <Card.Body>
                <Container fluid>
                  <Row>
                    {/* <Col class="col-7"> */}
                    <Col xs={{ span: 12, order: 0 }} lg={{ span: 7, order: 0 }}>
                      <Card.Title className="justify-text bold">
                        McMaster Radiation Shielding UHPC Designer
                      </Card.Title>
                      <div
                        style={{
                          borderTop:
                            "1px solid black" /* Define the border style */,
                          margin: "10px 0" /* Adjust margin as needed */,
                        }}
                      />
                      <Card.Text className="justify-text">
                        {/* <ReadMore charlimit={isMobile ? 500 : 1000}> */}
                        The McMaster Radiation Shielding UHPC Designer is an
                        advanced software tool developed to assist engineers and
                        researchers in designing ultra-high-performance concrete
                        (UHPC) mixtures with enhanced radiation shielding
                        properties. This innovative application allows users to
                        input their own materials, including cement, sand, and
                        aggregates, etc..., and provides a comprehensive
                        analysis of the mixture's particle size distribution and
                        theoretical radiation shielding capabilities.
                        <br />
                        <br />
                        <div className="bold">
                          Key features of the software include:
                        </div>
                        <ul>
                          <li>
                            Packing Density Evaluation: Utilizing the modified
                            Andreasson model, the software evaluates the packing
                            density of the mixture, providing crucial insights
                            into the compactness and potential performance of
                            the UHPC.
                          </li>
                          <li>
                            Attenuation Coefficient Evaluation: Using the
                            chemical composition of materials, the software can
                            calculate the attenuation coefficient of materials
                            and mixtures for both gamma and neutron radiations.
                          </li>
                          <li>
                            Optimization Capabilities: The software can perform
                            iterative optimizations to determine the optimal
                            proportions of ingredients, aiming to achieve the
                            highest possible packing density or radiation
                            shielding. This feature is particularly beneficial
                            for developing mixtures with superior mechanical
                            properties, durability, and radiation shielding.
                          </li>
                        </ul>
                        {/* <div className="bold">Future Enhancements:</div>
                        <li>Enhance the interface appearance</li>
                        <br />
                        <br /> */}
                        This tool is an invaluable asset for professionals in
                        the field of civil engineering, particularly those
                        focused on the development of nuclear containment
                        structures and other applications requiring robust
                        radiation shielding. By streamlining the mixture design
                        process and providing detailed analytical capabilities,
                        the McMaster Radiation Shielding UHPC Designer helps
                        users achieve optimal performance and safety standards
                        in their projects.
                      </Card.Text>
                      {/* <ExpandableComponent
                        closedStateText="Show Changelog"
                        openedStateText="Hide Changelog"
                      >
                        {radiationSoftwareChangelogs.map((changelog) => (
                          <p>{changelog.description}</p>
                        ))}
                      </ExpandableComponent> */}
                    </Col>

                    <Col
                    // class="col-5"
                    >
                      <Row>
                        <Image
                          src="images/Developments/RS-UHPC Designer.png"
                          fluid
                          className="my-2"
                        />
                      </Row>
                      <Row>
                        <Image
                          src="images/Developments/RS-UHPC Designer 2.png"
                          fluid
                          className="my-2"
                        />
                      </Row>

                      <Row>
                        <Col className="text-center">
                          <a
                            href="https://1drv.ms/u/c/db9c58436583d63b/Ec1Lg8NixwJDkrBv0tb5oD0Bd59-xUrbI-1w5W2DpCinWQ?e=ffMuxd"
                            target="_blank"
                            download
                            //   download={development.title}
                          >
                            <Button
                              variant="primary"
                              //   href={development.downloadLink}
                              //
                              className="my-2"
                            >
                              Download software
                            </Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </motion.div>
          {/* // </Row> */}
          {/* ))} */}
        </div>
      </Container>
    </>
  );
};
