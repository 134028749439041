import { useState } from "react";

export const ReadMore = ({
  children,
  charlimit,
}: {
  children: string;
  charlimit: number;
}) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="text">
      {isReadMore ? text.slice(0, charlimit) : text}
      <span
        onClick={toggleReadMore}
        className="read-or-hide bold"
        style={{ color: "green" }}
      >
        {isReadMore ? "...Read more" : " Show less"}
      </span>
    </p>
  );
};
